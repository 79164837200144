@import "../../brandConfig/colors/colors.scss";
@import "../../brandConfig/font-family/fonts.scss";
html {
    height: 100%;
}

body {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

* {
    box-sizing: border-box;
}

#root {
    flex: 1;
}

.container {
    width: 88%;
    margin: 0 auto;
    position: relative;
}

.container--flexbox {
    display: flex;
}

.container--center {
    display: flex;
    justify-content: center;
}

.white-background {
    background-color: #fff;
    padding: 20px 0 80px 0;
    min-height: 100%;
}

// modal
.ReactModal__Overlay.ReactModal__Overlay--after-open {
    position: fixed !important;
    z-index: 1003;
}

.ss-modal {
    font-family: $brand-font-family;
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    margin-right: -45%;
    max-width: 700px;
    max-height: 500px;
    transform: translate(-50%, -50%);
    z-index: 1002;
}

.ss-modal__buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    button {
        width: 45%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
    }
}

// styling cookie banner
.react-cookie-banner {
    position: fixed;
    width: 100%;
    height: auto;
    bottom: 0;
    padding: 15px;
    background: #414142 url(/cookie.png) 20px 50% no-repeat;
    color: #fff;
    background-size: 30px 30px;
    font-size: 15px;
    font-weight: 600;

    display: flex;
    flex-direction: column;
    align-items: center;

    .button-close,
    a {
        display: block;
        margin-top: 15px;
        flex-grow: 0;
    }

    a:hover {
        color: #fff !important;
    }

    a.active {
        color: $brand-color !important;
    }

    .button-close {
        color: #fff !important;
        border-color: #fff !important;
        font-weight: 400 !important;
        border-width: 1px !important;

        &:hover {
            font-weight: 700 !important;
        }
    }
}

// global styles
.ss-top-notification {
    margin-bottom: 50px;
}

.ss-list-header {
    text-transform: uppercase;
    font-size: 19px !important;
    margin: 0 0 13px 0;
}

@media screen and (min-width: 768px) {
    .container {
        width: 90%;
    }

    .white-background {
        padding-top: 30px;
        margin-right: 5%;
    }

    .react-cookie-banner {
        flex-direction: row;
        padding: 20px;

        .button-close {
            margin-left: 30px;
        }
    }

    .ss-modal {
        min-width: 500px;
    }

    .ss-modal--no-min-width {
        min-width: 0;
    }
}

@media screen and (min-width: 1313px) {
    .container {
        max-width: 1180px;
    }

    .white-background {
        margin-right: calc((100% - 1180px) / 2);
    }

    .white-background .container {
        margin-left: calc((100% - 1180px));
    }
}

@media screen and (min-width: 1441px) {
    .container {
        width: 82%;
    }
}
