// (C) Copyright 2014 Hewlett Packard Enterprise Development LP

.#{$grommet-namespace}notification {
  @include inuit-font-size($control-font-size, $inuit-base-spacing-unit);

  @each $status, $color in $brand-status-colors {
    &--status-#{$status} {
      // background-color comes from background-color-index

      .#{$grommet-namespace}notification__status {
        .#{$grommet-namespace}status-icon__base,
        .#{$grommet-namespace}status-icon__base .#{$grommet-namespace}status-icon__small {
          fill: $active-colored-text-color;
        }

        .#{$grommet-namespace}status-icon__detail {
          stroke: $color;
          fill: $color;
        }

        &.#{$grommet-namespace}status-icon-unknown {
          .#{$grommet-namespace}status-icon__detail {
            stroke: $active-colored-text-color;
            fill: $active-colored-text-color;
          }
        }
      }

      .#{$grommet-namespace}notification__close {
        stroke: $active-colored-text-color;
        fill: $active-colored-text-color;
      }

    }
  }
}

.#{$grommet-namespace}notification__message {
  @include inuit-font-size($content-large-font-size, $inuit-base-spacing-unit);

  + * {
    margin-top: $inuit-base-spacing-unit;
  }
}

.#{$grommet-namespace}notification__status {
  flex: 0 0 auto;
  margin-right: $inuit-base-spacing-unit;

  html.rtl & {
    margin-right: 0;
    margin-left: $inuit-base-spacing-unit;
  }
}

.#{$grommet-namespace}notification--small {
  .#{$grommet-namespace}notification {
    &__message {
      @include inuit-font-size($control-font-size, $inuit-base-spacing-unit);
    }
  }
}
