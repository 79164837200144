@import '../../../../brandConfig/colors/colors.scss';

.root {
  margin: 20px 0px 20px 0px;
  padding: 20px;
  background: lighten($brand-color, 70%);
  width: 98% !important;
  display: block !important;

  h3 {
    padding-bottom: 20px;
    margin-top: 0 !important;
  }
}

.bookingsLeft {
  padding-top: 10px;
  font-weight: bold;
}
.redirectButton {
  margin-top: 15px;
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  display: block !important;
}
@media (max-width: 768px) {
  .bookingsTable {
    overflow: scroll;
  }
  .PayForClass {
    display: flex;
  }
}
