.formContainer {
  display: flex;
  justify-content: space-around
}
.classConfigContainer{
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .formContainer {
    display: block;
    justify-content: space-around
  }
}