// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

$grommet-namespace: "grommetux-";

$border-radius: 0 !default;

// header/footer vertical sizing
$small-header-height: round($inuit-base-spacing-unit * 2) !default;
$header-height: round($inuit-base-spacing-unit * 3) !default;
$large-header-height: round($inuit-base-spacing-unit * 4) !default;
$small-footer-height: round($inuit-base-spacing-unit) !default;
$footer-height: round($inuit-base-spacing-unit * 1.5) !default;
$large-footer-height: round($inuit-base-spacing-unit * 4) !default;

// general layout t-shirt sizing
$size-xxsmall: round($inuit-base-spacing-unit * 2) !default; // 48px
$size-xsmall: round($inuit-base-spacing-unit * 4) !default; // 96px
$size-small: round($inuit-base-spacing-unit * 8) !default; // 192px
$size-medium: round($inuit-base-spacing-unit * 16) !default; // 384px
$size-large: round($inuit-base-spacing-unit * 24) !default; // 576px
$size-xlarge: round($inuit-base-spacing-unit * 30) !default; // 720px
$size-xxlarge: round($inuit-base-spacing-unit * 40) !default; // 960px

$center-column-width: round($inuit-base-spacing-unit * 48) !default; // 1152px;
$sidebar-width: round($inuit-base-spacing-unit * 14) !default; // 336px
$sidebar-xsmall-width: round($inuit-base-spacing-unit * 6) !default; // 144px
$sidebar-small-width: round($inuit-base-spacing-unit * 10) !default; // 240px
$sidebar-large-width: round($inuit-base-spacing-unit * 20) !default; // 480px
$nav-width: round($inuit-base-spacing-unit * 8) !default; // 192px
$form-width: round($inuit-base-spacing-unit * 20) !default; // 480px
$compact-form-width: round($inuit-base-spacing-unit * 12) !default; // 288px
$readable-text-width: round($inuit-base-spacing-unit * 24) !default; // 576px

$sparkline-height: $inuit-base-spacing-unit !default; // 24px

$control-size: $inuit-base-spacing-unit !default; // 24px

$button-min-width: round($inuit-base-spacing-unit * 5) !default;
$button-max-width: round($inuit-base-spacing-unit * 16) !default;

$input-border-width: 1px !default;
$input-focus-border-width: 2px !default;
$control-border-width: 2px !default;
$button-border-width: 2px !default;
$button-hover-border-width: 3px !default;
$range-border-radius: 3px !default;
$active-border-width: 4px !default;

$break-palm-max-width: 44.9375em !default;
$break-lap-and-up-min-width: 45em !default;
$break-portable-max-width: 63.9375em !default;

$breakpoints: (
    "palm"          "screen and (max-width: #{$break-palm-max-width})",
    "lap-and-up"    "screen and (min-width: #{$break-lap-and-up-min-width})",
    "portable"      "screen and (max-width: #{$break-portable-max-width})"
) !default;

$brand-link-text-decoration: none !default;

$button-text-transform: none !default;

$base-animation-duration: 0.3s;

$inuit-namespace: 'i-';
