.supersonicLogo {
    max-height: 60px;
    height: auto;
    display: inline-block;
    cursor: pointer;

    @media screen and (min-width: 768px) {
        display: inline-flex;
    }

    @media screen and (max-width: 1000px) {
        width: 80% !important;
        height: auto !important;
    }
}
