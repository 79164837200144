.checkboxContainer {
    display: flex;
    flex-direction: column;

    label {
        margin-bottom: 20px;
    }
}

.nextStepButton {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    cursor: pointer;

    &:first-of-type {
        margin-bottom: 20px;
    }
}

.termsOfWebsiteContainer {
    margin-bottom: 50px;
    p {
        margin: 0 0 15px 0 !important;
    }
}
