.listHeader {
    font-size: 19px !important;
    margin: 0 0 13px 0;
}

.TableHeader {
    border-bottom: 2px solid rgba(0, 0, 0, 0.15);
    font-weight: bold;
    color: black;
}
td {
    background: none;
}

.TableRowDesign {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.dashboardContainer {
    max-width: 888px;
    margin: 0 auto;
}
