// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP
.#{$grommet-namespace}password-input {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.#{$grommet-namespace}password-input__input {
  @include input();
  width: 100%;
  padding-right: round($inuit-base-spacing-unit * 2.5);
}

.#{$grommet-namespace}password-input__input:focus {
  padding-right: round($inuit-base-spacing-unit * 2.5 -
    ($input-border-width + 1));
}

.#{$grommet-namespace}password-input__control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: quarter($inuit-base-spacing-unit);
}
