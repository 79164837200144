.label {
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
}

.StripeElement {
    background-color: white;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid #DDDDDD;
    margin-bottom: 40px;
    background: #FFFFFF;
    max-width: 550px;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

@media (min-width: 768px) {
    .container {
        width: 50%;
    }
}

@media (min-width: 1280px) {
    .container {
        width: 340px;
    }
}
