.thankyouContainer {
    width: 100%;
    height: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textIconContainer {
    display: flex;
    flex-direction: column;
}

.textContainer {
    text-align: center;
}

.ssRound {
    height: auto;
    width: 74px;
    margin: 0 auto 20px auto;
}

.thankyouHeader {
    margin: 0 0 4px 0 !important;
}

.thankyouParagraph {
    margin: 0 !important;
}

@media (min-width: 1280px) {
    .textIconContainer {
        flex-direction: row;
        align-items: center;
    }

    .textContainer {
        text-align: left;
    }

    .ssRound {
        margin: 0 28px 0 0;
    }

    .thankyouHeader {
        margin-bottom: 4px !important;
    }
}
