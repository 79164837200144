.personalDetailsInput {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.select {
    max-width: 100px;

    input {
        height: 41px;
        padding: 2px;
        border: none !important;
    }

    button {
        padding-bottom: 6px;

        span {
            padding: 0 !important;
        }
    }
}

.selectLong {
    max-width: 170px;
}

.input {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: none;
    padding: 2px 0;
    overflow: hidden;

    & > span {
        margin: 0;
    }

    input {
        padding: 0 !important;
    }
}
.editIcon {
    position: absolute;
    right: 0;
    top: 30%;
    width: 16px;
    height: 16px;
}

.label {
    font-size: 18px;
    color: #695543;
    line-height: 20px;
    margin: 0 !important;
}

.warning {
    font-size: 12px;
    color: #ca0e0b;
}

@media screen and (min-width: 768px) {
    .personalDetailsInput {
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }

    .label {
        margin: 0 !important;
        width: 170px;
        flex-shrink: 0;
    }

    .select {
        margin-bottom: 0 !important;
    }
}

@media (min-width: 1280px) {
}
