.singleName {
  min-height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.singleNameText {
  margin-left: 12px;
  margin-top: 25px;
  margin-bottom: 0;
}