.optionContainer {
    margin: 20px 0;
    .categoryOption {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        border-bottom: 1px solid #ccc;
        margin: 5px 0;
        .tagInput {
            border: none;
            border-bottom: 1px solid #ccc;
        }
    }
}
.btnContainer {
    text-align: right;
    .btnCancel {
        margin: 0 5px;
    }
}
