.detailsContainer {
    display: flex;
    flex-direction: column;
}

.notesContainer {
    margin: 30px 0;
}

@media (min-width: 768px) {
    .container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}

.userActivationBox {
    display: flex;
    justify-content: space-between;
    background: #E1E1E1;
    padding: 30px;
    margin-bottom: 30px;
    vertical-align: center;
}
