// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

// CIRCLE

.#{$grommet-namespace}meter--circle {

  .#{$grommet-namespace}meter__graphic {
    width: $meter-size-default;
    min-width: $size-xsmall;
    height: auto;
  }

  .#{$grommet-namespace}meter__label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.#{$grommet-namespace}meter--xsmall {

    .#{$grommet-namespace}meter__graphic {
      width: $size-xsmall;
      height: $size-xsmall;
    }
  }

  &.#{$grommet-namespace}meter--small {

    .#{$grommet-namespace}meter__graphic {
      width: $size-small;
      height: $size-small;
    }
  }

  &.#{$grommet-namespace}meter--medium {

    .#{$grommet-namespace}meter__graphic {
      @include media-query(palm) {
        width: $size-small;
        height: $size-small;
      }

      @include media-query(lap-and-up) {
        width: $size-medium;
        height: $size-medium;
      }
    }
  }

  &.#{$grommet-namespace}meter--large {

    .#{$grommet-namespace}meter__graphic {
      @include media-query(palm) {
        width: $size-small;
        height: $size-small;
      }

      @include media-query(lap-and-up) {
        width: $size-large;
        height: $size-large;
      }
    }
  }

  &.#{$grommet-namespace}meter--xlarge {

    .#{$grommet-namespace}meter__graphic {
      @include media-query(palm) {
        width: $size-small;
        height: $size-small;
      }

      @include media-query(lap-and-up) {
        width: $size-xlarge;
        height: $size-xlarge;
      }
    }
  }
}
