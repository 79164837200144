.root {
  height: auto;
}

.selector {
  margin-bottom: 20px;
  span {
    font-size: 12px;
    color: #ca0e0b;
  }
}

.noData {
  font-size: 24px;
  color: #ca0e0b;
  font-weight: bold;
  margin-bottom: 30px;
}


