.container {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
}

.label {
    font-size: 18px;
    color: #666666;
    display: block;
}

.text {
    font-size: 18px;
    display: block;
    span {
      display: block;
    }
}

@media (min-width: 768px) {
    .label,
    .text {
        display: inline-block;
    }
    .label {
        min-width: 140px;
        padding-right: 20px;
    }
}

@media (min-width: 1280px) {
}
