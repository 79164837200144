.listHeader {
  font-size: 19px !important;
  margin: 0 0 13px 0;
}

.tableListHeader {
  margin: 0 !important;
  .noteTitle {
    font-weight: bold;
  }
}

.singleDay {
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.singleSession {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
