// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

$fade-in-duration: 0.5s;

@mixin fade-in() {
  -webkit-animation-name: fadein;
  -webkit-animation-duration: $fade-in-duration;
  animation-name: fadein;
  animation-duration: $fade-in-duration;
}

@-webkit-keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
      $animations: #{$animations + ", "};
    }
  }
  -webkit-animation: $animations;
  animation:         $animations;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin transform($args...) {
  -webkit-transform: $args;
  transform: $args;
}
