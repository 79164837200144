.manage-clients__filters {
  border: solid 1px rgba(0, 0, 0, 0.15);
  padding: 1em;
  margin-bottom: 1em; }

.manage-clients__filters__categories {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1em; }
  @media (min-width: 768px) {
    .manage-clients__filters__categories {
      flex-direction: row; }
      .manage-clients__filters__categories .manage-clients__filters__search {
        display: block; } }
  .manage-clients__filters__categories label {
    margin-bottom: 0.5em; }
  .manage-clients__filters__categories .client__active-filter,
  .manage-clients__filters__categories .client__product-filter,
  .manage-clients__filters__categories .client__gym-membership-filter {
    display: flex;
    flex-direction: column;
    margin-right: 0.5em;
    margin-bottom: 0.5em; }

.manage-clients__filters__search {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }
  .manage-clients__filters__search .client__type-filter {
    display: flex;
    flex-direction: column;
    margin-right: 0.5em; }
  .manage-clients__filters__search .client__gym-name-filter {
    padding-left: 5px; }

@media screen and (max-width: 768px) {
  .manage-clients__filters__search {
    display: block; }
  .client__gym-name-filter__gym {
    margin-top: 20px; }
  .client__type-filter {
    margin-top: 20px; }
  .client__name-filter {
    margin-top: 20px; } }
