// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.#{$grommet-namespace}date-time {
  position: relative;
  display: inline-block;
  // min-width: round($inuit-base-spacing-unit * 8);
}

.#{$grommet-namespace}date-time__input {
  @include input();
  padding-right: round($inuit-base-spacing-unit * 2.5);
}

.#{$grommet-namespace}date-time__input:focus {
  padding-right: round($inuit-base-spacing-unit * 2.5 -
    ($input-border-width + 1));
}

// Disable IE's clear icon
.#{$grommet-namespace}date-time__input::-ms-clear {
  display: none;
}

.#{$grommet-namespace}date-time__control {
  position: absolute;
  top: 50%;
  right: halve($inuit-base-spacing-unit);
  transform: translateY(-50%);
}

.#{$grommet-namespace}date-time-drop {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.#{$grommet-namespace}date-time-drop__title {
  text-align: center;
}

.#{$grommet-namespace}date-time-drop__grid {
  width: 100%;
  padding: halve($inuit-base-spacing-unit);

  table {
    width: 100%;
    margin-bottom: 0;
    outline: none;
  }

  th,
  td {
    text-align: center;
  }

  th {
    color: $secondary-text-color;
    font-weight: normal;
    padding: quarter($inuit-base-spacing-unit);
  }
}

.#{$grommet-namespace}date-time-drop__grid--focus {
  table {
    border-color: $focus-border-color;
    box-shadow: 0 0 1px 1px $focus-border-color;
  }
}

.#{$grommet-namespace}date-time-drop__day {
  display: inline-block;
  cursor: pointer;
  outline: none;
  width: round($inuit-base-spacing-unit * 1.5);
  height: round($inuit-base-spacing-unit * 1.5);
  padding: quarter($inuit-base-spacing-unit);
  transition: background-color 0.3s;
}

.#{$grommet-namespace}date-time-drop__day:hover,
.#{$grommet-namespace}date-time-drop__day--hover {
  background-color: $hover-background-color;
  color: $hover-text-color;
}

.#{$grommet-namespace}date-time-drop__day--other-month {
  color: $secondary-text-color;
}

.#{$grommet-namespace}date-time-drop__day--active {
  background-color: $brand-color;
  color: $colored-text-color;
  font-weight: 700;
}

.#{$grommet-namespace}date-time-drop__time {
  @include inuit-font-size(18px);
  font-weight: 700;
}
