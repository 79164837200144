.mainContainer {
    padding-top: 72px;
    height: calc(100% - 293px);
}

@media (min-width: 870px) {
    .mainContainer {
        height: calc(100% - 108px);
    }
}

@media (min-width: 785px) {
    .mainContainer {
        height: calc(100% - 77px);
    }
}

@media (min-width: 1280px) {
    .mainContainer {
        padding-top: 96px;
    }
}
