@import "../../../brandConfig/colors/colors.scss";
.footer {
    background-color: $brand-home-page-background ;
    padding: 23px 0;
    width: 100%;
}

.mobileMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    font-weight: 400;
}

.footerLogo {
    margin-bottom: 40px;
    max-width: 162px;
    max-height: 50px;
}

@media (min-width: 768px) {
    .footerContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .mobileMenu {
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        flex-wrap: wrap;
    }

    .footerLogo {
        margin-bottom: 0;
    }
}

@media (min-width: 974px) {
    .footerLogo {
        align-self: center;
    }
}

@media (min-width: 1280px) {
    .mobileMenu {
        align-items: center;
    }
}
