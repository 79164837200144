// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP
@import "../../../brandConfig/colors/colors.scss";
@import "../../../brandConfig/font-family/fonts.scss";
$brand-color: $brand-color;
$brand-color-secondary: $brand-color-secondary;
$brand-color-accent: $brand-color-accent;
$brand-neutral-colors: $brand-neutral-colors;
$brand-accent-colors: $brand-accent-colors;
$brand-link-color: $brand-link-color;
$brand-status-colors: (
  critical: #ca0e0b,
  error: #ca0e0b,
  warning: #f8e71c,
  ok: #80b91e,
  unknown: #a8a8a8,
  disabled: #a8a8a8,
  errorBackground: #fcc9c0,
) !default;
$brand-grey-colors: $brand-grey-colors;

$button-secondary-color: nth($brand-neutral-colors, 2);

$brand-font-family: $brand-font-family, "Montserrat", Arial, sans-serif;
$brand-large-number-font-family: "Montserrat", Arial, sans-serif;
$code-font-family: Consolas, Menlo, "DejaVu Sans Mono", "Liberation Mono", monospace;
$text-strong-font-weight: 500;

$fonts-path: "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700";

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Work Sans Light"), local("WorkSans-Light"),
    url("#{$fonts-path}/FD_Udbezj8EHXbdsqLUplxampu5_7CjHW5spxoeN3Vs.woff2") format("woff2");
}

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Work Sans"), local("WorkSans-Regular"),
    url("#{$fonts-path}//ElUAY9q6T0Ayx4zWzW63VJBw1xU1rKptJj_0jans920.woff2") format("woff2");
}

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  src: local("Work Sans Medium"), local("WorkSans-Medium"),
    url("#{$fonts-path}/Nbre-U_bp6Xktt8cpgwaJBampu5_7CjHW5spxoeN3Vs.woff2") format("woff2");
}

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Work Sans SemiBold"), local("WorkSans-SemiBold"),
    url("#{$fonts-path}/z9rX03Xuz9ZNHTMg1_ghGRampu5_7CjHW5spxoeN3Vs.woff2") format("woff2");
}

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Work Sans Bold"), local("WorkSans-Bold"),
    url("#{$fonts-path}/4udXuXg54JlPEP5iKO5AmRampu5_7CjHW5spxoeN3Vs.woff2") format("woff2");
}
