.iconContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .icon:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

.horizontalMobile {
    .icon:not(:last-child) {
        margin: 0 15px 0 0;
    }
}

.icon {
    cursor: pointer;
}

@media screen and (min-width: 555px) {
    .iconContainer {
        display: flex;
        flex-direction: row;

        .icon:not(:last-child) {
            margin: 0 15px 0 0;
        }
    }
}

@media screen and (min-width: 1280px) {
    .icon {
        width: 16px;
        height: 16px;

        &:not(:last-child) {
            margin: 0 25px 0 0;
        }
    }
}
