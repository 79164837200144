@import "../../../brandConfig/colors/colors.scss";
.sideDrawer {
    position: fixed;
    width: 300px;
    max-width: 90%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 1000;
    background-color: $brand-color;
    transition: transform 0.3s ease-out;
    display: flex;
    justify-content: center;
}

.open {
    transform: translateX(0);
}

.close {
    transform: translateX(100%);
}

.icon {
    position: fixed;
    right: 24px;
    top: 24px;
    z-index: 1001;
    fill: white;
    stroke: white;
}

.mobileMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
}

.space {
    height: 49px;
}

@media (min-width: 870px) {
    .sideDrawer {
        display: none;
    }
}
