// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

$map-stroke-width: round($inuit-base-spacing-unit / 12);
$map-active-stroke-width: round($inuit-base-spacing-unit / 4);

.#{$grommet-namespace}map {
  position: relative;
  z-index: 0;
  // padding: $inuit-base-spacing-unit;
}

.#{$grommet-namespace}map__links {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.#{$grommet-namespace}map__path {
  stroke-width: $map-stroke-width;
  @include graph-stroke-color();
}

.#{$grommet-namespace}map__path--active {
  stroke-width: $map-active-stroke-width;
}

.#{$grommet-namespace}map__categories {
  margin: 0px;
  list-style-type: none;
}

.#{$grommet-namespace}map__category {
  position: relative;
  // padding-top: $inuit-base-spacing-unit;
  margin-bottom: halve($inuit-base-spacing-unit);
  max-width: none;
}

.#{$grommet-namespace}map__category-label {
  // position: absolute;
  // top: 0px;
  // left: 0px;
  @include inuit-font-size($content-small-font-size);
  margin-bottom: halve($inuit-base-spacing-unit);
}

.#{$grommet-namespace}map__category-items {
  margin: 0px;
  list-style-type: none;
  overflow: hidden;
  text-align: center;
}

.#{$grommet-namespace}map__item {
  display: inline-block;
  margin-right: halve($inuit-base-spacing-unit);
  margin-bottom: halve($inuit-base-spacing-unit);

  #{$dark-background-context} {
    color: $text-color;
  }
}

.#{$grommet-namespace}map__item:not(.#{$grommet-namespace}map__item--plain) {
  width: $size-small;
  border: 1px solid $border-color;
  padding: quarter($inuit-base-spacing-unit) halve($inuit-base-spacing-unit);
  background-color: $background-color;
  @include inuit-font-size($content-font-size);

  > a {
    display: block;
    padding: quarter($inuit-base-spacing-unit) halve($inuit-base-spacing-unit);
    transition: background-color 0.2s;

    > * {
      display: inline-block;
    }

    &:hover {
      background-color: $hover-background-color;
    }
  }

  .#{$grommet-namespace}status-icon {
    margin-right: quarter($inuit-base-spacing-unit);
  }
}

.#{$grommet-namespace}map__item--active:not(.#{$grommet-namespace}map__item--plain) {
  border-color: $strong-border-color;
  border-width: 2px;
  padding: (quarter($inuit-base-spacing-unit) - 1px)
    (halve($inuit-base-spacing-unit) - 1px);
}

.#{$grommet-namespace}map--vertical {
  .#{$grommet-namespace}map__categories {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .#{$grommet-namespace}map__category-items {
    text-align: left;
  }

  .#{$grommet-namespace}map__item {
    display: block;
    margin-right: 0;
  }
}
