.drawerToggle {
    width: 24px;
    height: 38px;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.drawerToggle div {
    width: 20px;
    height: 2px;
    margin-bottom: 5px; 
}
.drawerToggle div {
    background-color: #fff;
}
.drawerDark div {
    background-color: #4e443d;
}

@media (min-width: 870px) {
    .drawerToggle {
        display: none;
    }
}
