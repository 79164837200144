.sing-up-summary__container {
    margin-left: 10px !important;
}

.sing-up-summary__label, .total-amount-chargeable__label {
    font-weight: 700;
}

.total-amount-chargeable__container {
    margin-top: 20px !important;
    padding-top: 10px;
    border-top: 2px solid #cccccc;
}

.total-amount-chargeable__label {
    padding-top: 20px !important;
    margin-left: 10px !important;
    font-size: 25px;
}
