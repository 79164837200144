@import "../../../brandConfig/colors/colors.scss";
.button {
  display: block !important;
  margin:20px 0px 20px auto;
  width:200px;
  text-align: center;
  padding: 10px 25px;
  font-weight: 700 !important;
  background: $brand-color !important;
  color: white !important;
  text-decoration: none !important;
}
