@import "../../brandConfig/colors/colors.scss";
.detailsContainer {
  width: 100%;
  padding: 50px 0;
  font-size: 14px;
}

.location {
  font-weight: 600;
  display: inline-block;
  margin-right: 100px;
}

.locationName {
  font-weight: 550;
  color: $brand-color-accent2;
  font-size: 2rem;
}

.header {
  margin-bottom: 10px;
  font-weight: 700;
}

.contactDetails {
  font-weight: 700;
}

.singleDetail {
  margin-bottom: 20px;
}

.label {
  display: block;
}

.iconsContainer {
  padding: 30px 0;
}

.contactIcon {
  margin-right: 30px;

  &:last-of-type {
    margin-right: 0;
  }
}

.icons {
  fill: $brand-color-accent2 !important;
}

.mapContainer {
  margin-top: 80px;
  height: 470px;
}

@media (min-width: 768px) {
  .singleDetail {
    display: flex;
  }

  .label {
    display: inline-block;
    flex-shrink: 0;
    min-width: 190px;
  }

  .mapContainer {
    height: 600px;
  }

  .detailsContainer {
    font-size: initial;
  }
}

@media (min-width: 1280px) {
  .mapContainer {
    position: absolute;
    top: 260px;
    right: 0;
    width: 720px;
    height: 470px;
  }

  .iconsContainer {
    margin-bottom: 260px;
  }
}
