// (C) Copyright 2014 Hewlett Packard Enterprise Development LP

.grommet.#{$grommet-namespace}drop {
    position: fixed;
    z-index: 1003;
    border-radius: $border-radius;
    overflow: auto;
}

.grommet.#{$grommet-namespace}drop:not([class*='background-color-index-']) {
    background-color: $drop-background;
    border: $drop-border;
    box-shadow: $drop-box-shadow;
}
