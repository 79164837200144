.singleTrainer {
  min-height: 44px;
  padding: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trainerName {
  margin-left: 12px;
  font-weight: bold;
  :last-child {
    font-weight: normal;
  }
}

@media screen and (min-width: 555px) {
  .singleTrainer {
    padding: auto;
  }
}

.gymAccess{
    font-weight: bold;
}