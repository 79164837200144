.videoInfo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .videoTitle{
        padding: 5px 5px 0;
        margin-bottom: 0;
    }
    .lastUpdatedDate{
        padding: 0 5px 5px;
    }
}

video {
    max-width: 100%;
    height: auto;
}
.videoThumbnail{
    width: 100%;
}