.header {
  z-index: 999;
  height: auto;
  color: #fff;
}

.headerMenu {
  width: 100%;
  justify-content: space-between;

  @media screen and (min-width: 870px) {
    display: inline-flex;
  }
}

.mobileMenuContainer {
  display: inline-flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.userMenuIcon {
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 870px) {
  .hiddenMobile {
    display: none;
  }
  .mobileMenuContainer {
    width: auto;
  }
  .mobileMenuBox {
    align-items: flex-end !important;
  }
}
@media screen and (min-width: 870px) {
  .mobileMenuContainer {
    display: none;
  }

  .userMenuIcon {
    width: 36px;
    height: 36px;
  }
}

@media (min-width: 1280px) {
  .header {
    height: 96px;
  }
}

.headerIcons {
  display: flex;
  margin: 0px 0px 0px auto;
}

.dashboardIcon {
  margin: auto;
}
