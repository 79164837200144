.tableContainer {
    overflow-x: scroll;
}
.table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    overflow: hidden;
    td,
    th {
        padding: 6px;
        border: 1px solid #eee;
    }
}
.titleColumn {
    text-align: left;
    position: relative;
    background: #fff;
    z-index: 9;
}
.day {
    width: 190px;
    svg {
        float: right;
    }
}

.nextBtnColumn {
    width: 40px;
    svg {
        cursor: pointer;
    }
}
.previousBtn {
    cursor: pointer;
}
.eventCell {
    width: calc(90% / 9);
    padding: 0 !important;
}
.event {
    padding: 5px;
    background: #20c7c5;
    border-radius: 4px;
    cursor: pointer;
    p {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}

.class {
    background: #f8f8f8;
}
.personalTraining {
    background: #ffeed6;
}
.induction {
    background: #e4fad5;
}
.gymClass {
    background: #d6e6ff;
}
.swimmingClass {
    background: #ffd6e6;
}
.tennisClass {
    background: #eed6ff;
}
.massageClass {
    background: #b3e5fc;
}
.xsOnly {
    display: none;
}
@media only screen and (max-width: 600px) {
    .xsHidden {
        display: none;
    }
    .xsOnly {
        display: block;
    }
    .table {
        th {
            width: 100px;
            position: relative !important;
        }
    }
    .titleColumn {
        text-align: center;
        position: absolute;
        width: 100px !important;
        min-height: 50px;
    }
    .eventCell {
        height: 50px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
    .titleColumn {
        white-space: normal !important;
        text-align: center;
        position: absolute;
        width: 120px !important;
        min-height: 80px;
    }
    .table {
        th {
            width: 100px;
            position: relative !important;
        }
    }
    .eventCell {
        height: 80px;
    }
}
