// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

$attribute-name-width: round($inuit-base-spacing-unit * 8);
$attribute-value-width: round($inuit-base-spacing-unit * 16);

.#{$grommet-namespace}object {
  overflow: auto;

  ul,
  ol {
    margin: 0px;
    list-style-type: none;
  }

  li {
    width: auto;
  }
}

.#{$grommet-namespace}object__container {
  padding: $inuit-base-spacing-unit;
}

.#{$grommet-namespace}object__attribute {
  margin-bottom: halve($inuit-base-spacing-unit);
}

.#{$grommet-namespace}object__attribute-name {
  display: block;
  color: $secondary-text-color;
  @include inuit-font-size($content-small-font-size);
}

.#{$grommet-namespace}object__attribute-value {
  display: block;
  @include inuit-font-size($content-font-size);

  ul,
  ol {
    margin-left: $inuit-base-spacing-unit;
    padding-top: $inuit-base-spacing-unit;
    padding-bottom: $inuit-base-spacing-unit;
  }
}

.#{$grommet-namespace}object__attribute--container {
  > .#{$grommet-namespace}object__attribute-name {
    font-weight: $text-strong-font-weight;
  }
}

.#{$grommet-namespace}object__attribute--unset {
  .#{$grommet-namespace}object__attribute-value {
    font-style: italic;
    color: $secondary-text-color;
  }
}

.#{$grommet-namespace}object__attribute--array {
  > .#{$grommet-namespace}object__attribute-value > ol {

    > li {
      border-top: 1px solid $border-color;

      &:last-child {
        border-bottom: 1px solid $border-color;
      }

      > ul {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }

  }
}
