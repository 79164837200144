.profilePicture {
    cursor: pointer;
    align-self: center;
    flex-shrink: 0;
    flex-grow: 0;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin: 20px;
}

input[type='file'] {
    visibility: hidden;
    position: absolute;
}

@media (min-width: 768px) {
    .profilePicture {
        width: 100px;
        height: 100px;
    }

    .imageUploadContainer {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1280px) {
    .profilePicture {
        width: 160px;
        height: 160px;
    }
}
