// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.#{$grommet-namespace}form {
  position: relative;
  max-width: 100%;

  @include media-query(lap-and-up) {
    // For some reason, Tiles inside FormField doesn't determine its
    // width correctly. Enforce it here.
    .#{$grommet-namespace}form-field .#{$grommet-namespace}tiles__container {
      max-width: $form-width;
    }
  }

  @include pad();

  > .#{$grommet-namespace}header .#{$grommet-namespace}header__wrapper {
    background-color: inherit;
  }

  fieldset {
    // needed to avoid form fields overflow form element
    min-width: 0;
    border: none;
    margin: 0px;
    margin-bottom: 2rem;
    margin-top: $inuit-base-spacing-unit;

    &:first-child {
      margin-top: 0px;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    > legend {
      @include inuit-font-size(24px);
      font-weight: $text-strong-font-weight;
      margin-bottom: halve($inuit-base-spacing-unit);
    }

    > *:not(.#{$grommet-namespace}form-field) + .#{$grommet-namespace}form-field {
      margin-top: halve($inuit-base-spacing-unit);
    }

    > .#{$grommet-namespace}form-field + *:not(.#{$grommet-namespace}form-field):not(.#{$grommet-namespace}form-fields) {
      margin-top: $inuit-base-spacing-unit;
    }

    > .#{$grommet-namespace}form-fields {
      display: flex;
      flex-direction: row;

      .#{$grommet-namespace}form-field {
        margin-bottom: -1px;
      }

      > .#{$grommet-namespace}button {
        flex: 0 0 auto;
      }
    }
  }
}

.#{$grommet-namespace}form:not(.#{$grommet-namespace}form--plain) {
  width: $form-width;
}

.#{$grommet-namespace}form--fill {
  min-width: 0px;
}

.#{$grommet-namespace}form--compact {
  max-width: $compact-form-width;
}
