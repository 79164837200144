@import "../../brandConfig/colors/colors.scss";

.messagePanel {
    max-width: 100% !important;
}

.messages {
    padding: 5px 20px 5px 20px;
    margin: 20px 0px 20px 0px;
    border: 1px solid black;
    box-shadow:  0 0 5px  rgba(0,0,0,0.6);
    -moz-box-shadow: 0 0 5px  rgba(0,0,0,0.6);
    -webkit-box-shadow: 0 0 5px  rgba(0,0,0,0.6);
    -o-box-shadow: 0 0 5px rgba(0,0,0,0.6);
    border-radius: 3px;
    text-align: left;
    height: 150px;
    width: inherit;
    overflow: auto;
}

.messageColorPicker {
    text-align: left;
}

.messageColorLabel {
    color: nth($brand-grey-colors, 5) !important;
}

.messageColor {
    margin-left: 15px !important;
    cursor: pointer;
    padding: 0px !important;
}

.messageColorPicker {
    text-align: left;
    margin-bottom: 20px;
}

.messageForm {
    display: inline-flex;
    width: 100% !important;
}

.message {
    margin-right: 20px !important;
}

.sendButton {
    display: inline-block;
    margin-left: 20px;
}