.smallPreview {
    z-index: 999;
    background-color: #fff;
    position: fixed;
    bottom: 50%;
    right: -80px;
    transform: rotate(-90deg);
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    width: 200px;
    height: 41px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    cursor: pointer;
}


.scannerBox {
    z-index: 999;
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    position: fixed;
    bottom: 50%;
    right: 0;
    padding: 10px;
    font-size: 12px;
    display: inline-block;
}

.closeIcon {
    position: absolute;
    cursor: pointer;
    top: 15px;
    right: 15px;
}

.scannerContent {
    display: flex;
}

.checkboxContainer {
    display: flex;
    flex-direction: column;
}

.checkbox{
    flex-grow: 0;
    flex-shrink: 0;
    &:first-child {
        margin: 50px 0 25px 0;
    }
}

@media (min-width: 768px) {
    .scannerBox {
        right: 20px;
    }
}

@media (min-width: 1280px) {
}
