.messagePanel {
    max-width: 100% !important;
}

.joinStreamButtonPanel {
    margin-top: 20px;
}

.messageContainer {
    display: inline-flex;
    width: 100%;
}

.message {
    width: 100%;
    margin-right: 20px !important;
}

.sendButton {
    display: inline-block;
}

.messages {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid black;
    box-shadow:  0 0 5px  rgba(0,0,0,0.6);
    -moz-box-shadow: 0 0 5px  rgba(0,0,0,0.6);
    -webkit-box-shadow: 0 0 5px  rgba(0,0,0,0.6);
    -o-box-shadow: 0 0 5px rgba(0,0,0,0.6);
    border-radius: 3px;
    text-align: left;
    height: 150px;
    width: inherit;
    padding: 5px;
    overflow: auto;
}

.messageColor {
    margin-left: 15px !important;
    cursor: pointer;
    padding: 0px !important;
}

.messageColorPicker {
    text-align: left;
}

.messageColorLabel {
    color: #666 !important;
}

.signalServerErrorMessage {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  color: red;
  font-weight: 600;
}