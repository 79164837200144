// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP
.#{$grommet-namespace}article {
  position: relative;

  > * { // too generic, change
    flex: 0 0 auto;
  }
}

.#{$grommet-namespace}article--scroll-step {
  text-align: center;
  height: 100vh;
  width: 100vw;
  max-width: 100%;

  &.#{$grommet-namespace}box--direction-column {
    overflow-x: hidden;
    overflow-y: auto;

    .#{$grommet-namespace}article__control-carousel {
      top: 50%;
      left: $inuit-base-spacing-unit;
      transform: translateY(-50%);
    }
  }

  &.#{$grommet-namespace}box--direction-row {
    overflow-x: auto;
    overflow-y: hidden;

    > *:not(.#{$grommet-namespace}article__controls) { // too generic, change
      overflow-y: auto;

      // https://css-tricks.com/snippets/css/momentum-scrolling-on-ios-overflow-elements/
      @include media-query(palm) {
        overflow-y: scroll; // has to be scroll, not auto
        -webkit-overflow-scrolling: touch;
      }
    }

    .#{$grommet-namespace}article__control-carousel {
      top: $inuit-base-spacing-unit;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @include media-query(palm) {
    &.#{$grommet-namespace}box--responsive {
      &.#{$grommet-namespace}box--direction-row {
        flex-direction: row;
      }
    }
  }
}

.#{$grommet-namespace}article__control {
  position: fixed;
  z-index: 10;
  // using margin instead of padding for article control
  // to prevent arrow from overlapping scrollbar and
  // hindering scrollbar usage
  margin: $inuit-base-spacing-unit;

  // removes scrollbars from article control arrows in mobile
  &.#{$grommet-namespace}button--plain.#{$grommet-namespace}button--icon {
    overflow: hidden;
  }

  .#{$grommet-namespace}button__icon {
    padding: 0;
  }
}

.#{$grommet-namespace}article__control-up {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.#{$grommet-namespace}article__control-down {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.#{$grommet-namespace}article__control-left {
  @include media-query(lap-and-up) {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  @include media-query(palm) {
    left: 0;
    bottom: 0;
  }
}

.#{$grommet-namespace}article__control-right {
  @include media-query(lap-and-up) {
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  @include media-query(palm) {
    right: 0;
    bottom: 0;
  }
}

.grommet article:not(.#{$grommet-namespace}article) {
  width: 100%;
}
