.bottomSpace{
  margin-bottom: 20px
}
.spinner, .saveBtn {
  margin-top: 50px;
  display: block;
}
.scheduledList {
   display: flex; 
   justify-content: flex-end;
   margin-bottom: 20px;
}
.noData {
  font-size: 24px;
  color: #ca0e0b;
  font-weight: bold;
  margin-bottom: 30px;
}

.modalFooter{
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}