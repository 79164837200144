@import "../../../../brandConfig/colors/colors.scss";

.videoModalContainer {
    display: flex;
    .videoDetailContainer {
        .videoDetailLabel {
            font-weight: 700;
        }
    }
    .videoForm {
        width: 50%;
        padding: 0 10px;
    }
    .videoUploadForm {
        padding: 0 10px;
        width: 50%;
    }
}

.modalButtons {
    padding: 10px 10px;
    text-align: right;
    .btnCancel {
        margin: 0 5px;
    }
}

.input {
    margin-bottom: 20px !important;
    margin-right: 0px;
    visibility: visible !important;
    width: 100% !important; 
}

.chooseFileButton {
    font-size: 19px;
    border-style: solid;
    border-color: $brand-link-color;
    background: $brand-link-color;
    border-width: 2px;
    font-weight: 700;
    color: #fff;
    padding: 6px 22px;
    position: inherit;
}

.chooseFileButton[disabled] {
    opacity: 0.3;
}

.chooseFileButton[disabled]:hover {
    cursor: default;
}

.chooseFileButton:hover {
    cursor: pointer;
    box-shadow: 0px 0px 0px 2px $brand-link-color !important;
}

@media (min-width: 768px) {
    .videoUploadContainer {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }
}