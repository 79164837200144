@import '../../../brandConfig/colors/colors.scss';
.addNewButton {
  display: flex;
  justify-content: space-between;
  button {
    width: 201px;
  }
}

.filtersContainer {
  border: 1px solid $brand-color;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  h4 {
    margin: 10px 0px;
  }
  button {
    width: 200px;
  }
}

.checkbox {
  display: block;
  margin: 5px;
}

.classNameFilter {
  height: 48px;
}
