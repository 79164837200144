.textContainer {
    margin-bottom: 30px;
}

.header {
    margin: 0 0 6px 0 !important;
}

.checkboxLabel {
    margin-bottom: 40px;
    display: block;
}

.nextStepButton {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;

    &:first-of-type {
        margin-bottom: 20px;
    }
}
