.sourceContainer {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}

.source {
    border: 1px solid #dddddd;
    padding: 10px;
    width: 100%;
    background-color: #eeeeee;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
}

.icon {
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-left: 10px;
}
