.profile-details__container {
    // margin-top: 100px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.profile-details__header {
    font-size: 24px;
    text-transform: uppercase;
    margin: 30px 0;
}

.last-payment {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
