@import '../../brandConfig/colors/colors.scss';
.root {
  width: 100%;
}
.uploadFileButton {
  padding: 8px 22px;
  border: 2px solid $brand-color;
  font-weight: bold;
}
.uploadFileButton:hover {
  cursor: pointer;
}

.alignToRight {
  text-align: right;
}


.jsonExample{
 margin-right: 10px
}