.listHeader {
    font-size: 19px !important;
    margin: 0 0 13px 0;
    text-transform: uppercase;
}

.addAdminButton{
    float: right;
    * {
        padding: 5px;
        font-weight: bold;
    }
}