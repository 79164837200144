.tableContainer {
    overflow-x: auto;
}
.table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    td,
    th {
        padding: 6px;

        border: 1px solid #eee;
    }
}
.personColumn {
    width: 170px;
    text-align: left;
}
.monthLabels {
    font-size: 12px;
    padding: 0 !important;
}
.weekNumber svg {
    float: right;
}
.eventCell {
    vertical-align: top;
}
.event {
    width: 100%;
    height: 6px;
    margin-top: 0 !important;
    margin-bottom: 2px !important;
    background: #20c7c5;
    border-radius: 4px;
    cursor: pointer;
}
.bethInterview {
    background: #f8f8f8;
}
.xsOnly {
    display: none;
}
@media only screen and (max-width: 600px) {
    .xsHidden {
        display: none;
    }
    .xsOnly {
        display: block;
    }
    .table {
        td {
            height: 45px;
        }
        th {
            width: auto;
            position: relative !important;
        }
    }
    .titleColumn, th.titleColumn{
        text-align: center;
        position: absolute;
        min-height: 45px;
        background: #fff;
        width: 100px;
    }
}
