.personalDetailsInput {
    // &:nth-child(4) {
    //     margin-bottom: 54px;
    // }
}

.select {
    max-width: 130px;
    margin-bottom: 20px !important;
}

.selectLong {
    max-width: 300px;
}

.input {
    margin-bottom: 20px !important;
    visibility: visible !important;
    width: 100% !important;
}

.label {
    display: block;
    margin: 0 0 4px 0 !important;
}

.warning {
    font-size: 12px;
    color: #ca0e0b;
    margin: -17px 0 17px 0;
}

.checkBoxWithBottomMargin {
    margin-bottom: 15px;
}
